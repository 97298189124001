import React, { useEffect, useState } from "react";
import Layout from "../components/common/Layout";
import Section from "../components/common/Section";
import SEO from "../components/common/SEO";
import { H1, H3, P } from "../components/common/Typography";
import { MEDIA_QUERIES } from "../config";
import Divider from "../components/common/Divider";
import { Formik, Form, Field } from "formik";
import Input from "../components/common/Input";
import TextArea from "../components/common/TextArea";
import Button from "../components/common/Button";
import * as Yup from "yup";
import axios from "axios";

const TITLE = "Επικοινωνία";

function Message() {
  return (
    <P
      css={`
        margin-bottom: 72px;
      `}
    >
      Ευχαριστούμε για το μήνυμά σας. <br />
      Θα σας απαντήσουμε το συντομότερο δυνατό.
    </P>
  );
}

function ContactPage() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage("");
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <Layout>
      <SEO title={TITLE} />

      <main>
        <Section
          css={`
            padding: 72px 0;
            text-align: center;

            ${H1} {
              margin-bottom: 32px;
            }

            ${H3} {
              margin-top: 72px;
              margin-bottom: 32px;
            }

            ${P} {
              margin-left: auto;
              margin-right: auto;
              max-width: 786px;
              opacity: 0.48;
            }

            ${MEDIA_QUERIES.mobile} {
              padding: 32px 16px;
            }
          `}
        >
          <div
            css={`
              margin-bottom: 72px;
            `}
          >
            <H1>{TITLE}</H1>
            <P>
              Η γραμματεία της σχολής λειτουργεί από Δευτέρα έως Παρασκευή 16:00
              - 21:00 & Σάββατο 11:00 - 14:00. <br />
              Για περισσότερες πληροφορίες, παρακαλούμε επικοινωνήστε μαζί μας:
            </P>
          </div>
          {message || (
            <Formik
              initialValues={{ name: "", email: "", message: "" }}
              validationSchema={Yup.object({
                name: Yup.string().required("Required"),
                email: Yup.string().email("Invalid email").required("Required"),
                message: Yup.string().required("Required"),
              })}
              onSubmit={async (values, actions) => {
                await axios.post("/.netlify/functions/sendMail", values);
                actions.resetForm({ name: "", email: "", message: "" });
                setMessage(<Message />);
              }}
            >
              <Form
                css={`
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  grid-template-rows: 48px 184px 48px;
                  gap: 24px;
                  max-width: 675px;
                  margin: 0 auto;
                  margin-bottom: 72px;
                `}
              >
                <Field name="name" component={Input} placeholder="Όνομα" />
                <Field name="email" component={Input} placeholder="Email" />
                <Field
                  name="message"
                  component={TextArea}
                  placeholder="Γράψτε εδώ το μήνυμα σας"
                />
                <div
                  css={`
                    grid-column: 1 / 3;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  <Button.Arrowed type="submit">Αποστολή</Button.Arrowed>
                </div>
              </Form>
            </Formik>
          )}
          <Divider />
          <H3>ΣΤΟΙΧΕΙΑ ΕΠΙΚΟΙΝΩΝΙΑΣ</H3>
          <P>Τηλ.: 210 5158735</P>
          <P>Mail: info@athensdanceschool.gr</P>
          <P>Διεύθυνση: Μαραθωνομάχων 51, Ακαδημία Πλάτωνος, 10441, Αθήνα</P>
        </Section>
        <iframe
          id="contact"
          title="Athens Dance School Location"
          height={450}
          seamless
          allowFullScreen
          width="100%"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3144.4947372280826!2d23.7093093!3d37.9889192!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1bcd0b61d2feb%3A0x869703f75cb4be08!2zTWFyYXRob25vbWFjaG9uIDUxLCDOkc64zq7Ovc6xIDEwNCA0MSwgR3JlZWNl!5e0!3m2!1sen!2sus!4v1441366852839"
        />
      </main>
    </Layout>
  );
}

export default ContactPage;
