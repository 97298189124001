import React from "react";

export default function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) {
  return (
    <div>
      <input
        css={`
          width: 100%;
          padding: 12px 16px;
        `}
        type="text"
        {...field}
        {...props}
      />
      {touched[field.name] && errors[field.name] && (
        <div
          css={`
            text-align: left;
            font-size: 14px;
            color: red;
          `}
        >
          {errors[field.name]}
        </div>
      )}
    </div>
  );
}
